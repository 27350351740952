@font-face {
  font-family: "Exo 2";
  src: url(./fonts/Exo2-Regular.ttf);
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: "Exo 2" !important;
}
